.container {
  padding: 0 2rem;
}

.main {
  min-height: 100vh;
  padding: 4rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.footer {
  display: flex;
  flex: 1;
  padding: 2rem 0;
  border-top: 1px solid #eaeaea;
  justify-content: center;
  align-items: center;
}

.footer a {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.title a {
  color: #0070f3;
  text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
  text-decoration: underline;
}

.title {
  margin: 0;
  line-height: 1.15;
  font-size: 4rem;
  animation: glow 4s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    text-shadow:
      0 0 10px #fff,
      0 0 20px #fff,
      0 0 30px #08029d,
      0 0 40px #08029d,
      0 0 50px #08029d,
      0 0 60px #08029d;
  }

  to {
    text-shadow:
      0 0 20px #fff,
      0 0 30px #4dafff,
      0 0 40px #4dafff,
      0 0 50px #4dafff,
      0 0 60px #4dafff,
      0 0 70px #4dafff;
  }
}

.title {
  text-align: center;
}

.code {
  background: #111;
  border-radius: 5px;
  padding: 0.75rem;
  font-size: 1.1rem;
  font-family:
    Menlo,
    Monaco,
    "Lucida Console",
    "Liberation Mono",
    "DejaVu Sans Mono",
    "Bitstream Vera Sans Mono",
    "Courier New",
    monospace;
}

.grid {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 800px;
}

.card {
  margin: 1rem;
  padding: 1.5rem;
  text-align: left;
  color: inherit;
  text-decoration: none;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  transition: color 0.15s ease, border-color 0.15s ease;
  max-width: 300px;

  .footer {
    border-color: #222;
  }
}

.card:hover,
.card:focus,
.card:active {
  color: #0070f3;
  border-color: #0070f3;
}

.card h2 {
  margin: 0 0 1rem;
  font-size: 1.5rem;
}

.card p {
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.5;
}

.logo {
  height: 1em;
  margin-left: 0.5rem;

  img {
    filter: invert(1);
  }
}

.overview {
  text-align: center;
  margin-bottom: 4rem;

  .description {
    width: 80vw;
    margin: 4rem 0 0.2rem 4rem;
    line-height: 1.5;
    font-size: 1.5rem;
  }

  .subtext {
    color: #d2d2d2;
    font-size: 1.1rem;
    margin-bottom: 2rem;
  }

  .cta {
    padding: 16px 32px;
    color: #fff;
    background-color: #0000fff7;
    border: 0;
    border-radius: 6px;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
  }
}

@media (max-width: 600px) {
  .title {
    font-size: 2.9rem;
  }

  .grid {
    width: 100%;
    flex-direction: column;
  }

  .description {
    font-size: 1.1rem;
  }

  .subtext {
    font-size: 0.75rem;
  }

  .cta {
    font-size: 0.9rem;
  }
}

.productList {
  > h1 {
    text-align: center;
    font-size: 1.6rem;
  }

  .product {
    display: flex;
    flex-direction: row;
    gap: 16px;

    border: white solid 2px;
    border-radius: 16px;
    padding: 16px 32px;
    cursor: pointer;

    &:hover {
      background-color: #3745fa;
      border-color: #abb3ff;
      animation: glow 1s ease-in-out infinite alternate;
    }

    .productLogo {
      img {
        height: 64px;
      }
    }

    .productText {
      display: flex;
      flex-direction: column;
      margin-top: 0.4rem;

      .productName a {
        font-size: 1.2rem;
        font-weight: bold;
      }

      .productDescription {
        font-size: 0.9rem;
        color: #d2d2d2;
      }
    }
  }
}
